<template>
    <section id="technical-details">
      <card icon="laptop-medical"
            :headline="$t('visitors.checks.headline')"
            :description="$t('visitors.checks.description')"
      >

      </card>
    </section>
</template>

<script>
    export default {
        name: "TechnicalDetails"
    }
</script>

<style scoped>

</style>
